/* DEFAULT FONT AND REMOVE BODY MARGIN */
html,
body {
  width: 100%;
  height: 100%;
}
html,
body,
input,
button {
  font-family: "museo_sans500", sans-serif;
}
body {
  margin: 0;
}
#root,
.main-app-wrapper {
  height: 100%;
}

/* DISABLE IE X BUTTON FOR INPUTS */
::-ms-clear {
  display: none;
}

/* FADE-IN STYLES */

.fade-in {
  animation: fadein 2s;
  -moz-animation: fadein 2s; /* Firefox */
  -webkit-animation: fadein 2s; /* Safari and Chrome */
  -o-animation: fadein 2s;
}
@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* LOADER & MODAL STYLES */
body.loader-active,
body.modal-active {
  overflow-y: hidden;
}
body.modal-active.full-screen-modal {
  overflow-y: auto;
}
body.modal-active.full-screen-modal .not-last-modal,
body.modal-active.full-screen-modal .hidden-on-fullscreen-modal {
  display: none;
}
body.mobile-navigation {
  overflow-y: hidden;
}
hr {
  border: none;
  border-bottom: solid 1px lightgrey;
}

/* TABLE STYLES */
.table-border-collapse {
  border-collapse: collapse;
}
td {
  padding: 0;
}

/* DESKTOP/MOBILE CONTROL */
@media only screen and (max-width: 992px) {
  .desktop-component {
    display: none !important;
  }
}
@media only screen and (min-width: 992px) {
  .mobile-component {
    display: none !important;
  }
}

/* GENERIC STYLES */
.vertical-align-middle {
  vertical-align: middle;
}
.text-align-center {
  text-align: center;
}
.ws-nowrap {
  white-space: nowrap;
}
.ta-left {
  text-align: left;
}
.w-100-percent {
  width: 100%;
}
.fw-bold {
  font-weight: bold;
}
.hidden-component {
  display: none;
}
.visibility-hidden-component {
  visibility: hidden;
}

/* FLEX STYLES (NAMES WITH SHORTCUTS) */
.display-flex {
  display: flex;
}
.fia-center {
  align-items: center;
}
.fd-row {
  flex-direction: row;
}
.fd-column {
  flex-direction: column;
}
.fjc-space-around {
  justify-content: space-around;
}
.fjc-space-between {
  justify-content: space-between;
}
.fjc-flex-end {
  justify-content: flex-end;
}
.fjs-center {
  justify-content: center;
}
.fjs-start {
  justify-content: start;
}
.fff-row-wrap {
  flex-flow: row wrap;
}
.fff-row-nowrap {
  flex-flow: row nowrap;
}
.flex-basis-30 {
  flex-basis: 30%;
}
.flex-divided-4-cols {
  flex: 0 0 24%;
}
.flex-divided-3-cols {
  flex: 0 0 33.3333%;
}
.flex-divided-2-cols {
  flex: 0 0 50%;
}
.flex-wrap {
  flex-flow: wrap;
}

.flex-parent-row-wrap-space-around-container {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
}
.flex-parent-row-nowrap-space-around-container {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-around;
}
.flex-parent-row-wrap-center-container {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
}
.flex-parent-row-nowrap-center-container {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
}

/* MODALS */
button.modal-close-button {
  min-width: 30px;
}

/* LOADER */
.loader-16x16 {
  width: 16px;
}
.loader-17x17 {
  width: 17px;
}
.loader-18x18 {
  width: 18px;
}

/* OVERRIDE material ui stuff */
.MuiButton-label {
  text-transform: initial;
}

/* Google Autocmplete Places Style*/
.pac-container {
  box-shadow: unset;
  border: solid 1.2px #2699fb;
  border-radius: 3px;
  font-family: museo_sans300;
}
@media only screen and (min-width: 992px) {
  .pac-container {
    min-width: 420px !important;
  }
}
.pac-matched {
  font-family: museo_sans500;
}
.pac-item {
  padding: 5px;
  font-size: 14px;
}
.pac-item-query {
  font-family: museo_sans500;
  font-size: 14px;
}
/*UPS Tube svg style*/
.st0 {
  fill: none;
  stroke: #000000;
  stroke-width: 1.1327;
  stroke-miterlimit: 10;
  stroke-dasharray: 6.7963, 3.3981;
}

/*input number remove arrows*/
/*firefox*/
input[type="number"] {
  -moz-appearance: textfield;
}
/*other browsers*/
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/*outline on chrome on macOS*/
input:focus {
  outline: solid 1.5px #2699fb;
}
